import CardList from '@components/CardList'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import { Layout, Main, PreFooter, Stack } from '@layout'
import BannerHorizontal from '@widgets/BannerHorizontal'
import Seo from '@widgets/Seo'
import React from 'react'

const PageCollection = ({ data: { posts, collectionInfo }, ...props }) => (
  <Layout {...props}>
    <Seo title={collectionInfo.name} description={collectionInfo.description} />
    <Divider />
    <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
        header={collectionInfo.name}
        subheader={collectionInfo.title}
      // running={collectionInfo.description}
      // totalCount={posts.totalCount}
      />
    </Stack>
    <Divider />
    {/* <BannerHorizontal /> */}
    <Divider />
    <Stack>
      <Main>
        {posts.nodes && (
          <CardList
            nodes={posts.nodes}
            variant={['horizontal-md', 'vertical']}
            columns={[1, 2, 3, 3]}
            omitCategory={
              props.pageContext &&
              props.pageContext.collectionType === 'category'
            }
          />
        )}
      </Main>
    </Stack>
    <Divider />
    <PreFooter>
      <Pagination {...posts.pageInfo} {...collectionInfo} />
    </PreFooter>
    <Divider />
    {/* <BannerHorizontal /> */}
  </Layout>
)

export default PageCollection
